import Under from './img/under.jpg';
const Blog = () => {
    return (
        <>
            <section id="blog-content" className="">
                <div className="book-block">
                    <img src={Under} alt="Under" className="img-fluid"  />
                </div>
            </section>
        </>
    );
}

export default Blog;